import React from "react";

function LandingPage() {
  return (
    <div className="w-100 container-bkp">
      <img
        src="/landing-page.jpg"
        alt="landing-page-image"
        style={{ width: "100%" }}
      />
    </div>
  );
}

export default LandingPage;
