// @ts-nocheck
/* eslint-disable import/no-extraneous-dependencies */
import React, { useState } from "react";
import { NavLink } from "react-router-dom";
import { Navbar, Nav, Container } from "react-bootstrap";
import PropTypes from "prop-types";
import CONSTANTS from "../../common/utils/CONSTANTS";
import HeaderStylesWrapper from "./styled";
import "bootstrap/dist/css/bootstrap.min.css"; // Ensure this is imported

function Header(props) {
  const loggedInUserName =
    localStorage.getItem("userDetails") &&
    JSON.parse(localStorage.getItem("userDetails")).username;

  const [isNavExpanded, setIsNavExpanded] = useState(false); // Navbar toggle state
  // Close navbar when a menu item is clicked
  const handleNavSelect = () => {
    setIsNavExpanded(false);
  };
  const getNavLinkActiveClass = ({ isActive }) =>
    isActive ? "active nav-link" : "nav-link";

  return (
    <HeaderStylesWrapper>
      <header>
        <Navbar
          sticky="top"
          bg="dark"
          data-bs-theme="dark"
          expand="lg"
          className="mb-4"
          expanded={isNavExpanded} // Control expanded state
        >
          <Container>
            <Navbar.Brand href={props.isAuthenticated ? "/dashboard" : "/"}>
              HeyNeighbors!{" "}
              <span className="small-font">{`V${CONSTANTS.APP_VERSION}`}</span>
            </Navbar.Brand>
            {props.isAuthenticated && (
              <>
                <Navbar.Toggle
                  aria-controls="basic-navbar-nav"
                  onClick={() => setIsNavExpanded(!isNavExpanded)}
                />
                <Navbar.Collapse id="basic-navbar-nav">
                  <Nav className="ml-auto">
                    <Nav.Link
                      as={NavLink}
                      to="/dashboard"
                      className={getNavLinkActiveClass}
                      onClick={handleNavSelect}
                    >
                      Dashboard
                    </Nav.Link>
                    <Nav.Link
                      as={NavLink}
                      to="/night-patrolling"
                      className={getNavLinkActiveClass}
                      onClick={handleNavSelect}
                    >
                      Night Patrolling
                    </Nav.Link>
                    <Nav.Link
                      as={NavLink}
                      to="/attendance"
                      className={getNavLinkActiveClass}
                      onClick={handleNavSelect}
                    >
                      Attendance
                    </Nav.Link>
                    <Nav.Link
                      as={NavLink}
                      to="/maintenance-bills"
                      className={getNavLinkActiveClass}
                      onClick={handleNavSelect}
                    >
                      Maintenance Bills
                    </Nav.Link>
                    <Nav.Link
                      as={NavLink}
                      to="/expenses-tracking"
                      className={getNavLinkActiveClass}
                      onClick={handleNavSelect}
                    >
                      Expenses Tracking
                    </Nav.Link>
                    <Nav.Link onClick={props.handleLogout}>
                      Logout - {String(loggedInUserName).toUpperCase()}
                    </Nav.Link>
                  </Nav>
                  )
                </Navbar.Collapse>
              </>
            )}
          </Container>
        </Navbar>
      </header>
    </HeaderStylesWrapper>
  );
}

Header.propTypes = {
  isAuthenticated: PropTypes.bool.isRequired,
  handleLogout: PropTypes.func.isRequired,
};

export default Header;
