/* eslint-disable jsx-a11y/anchor-is-valid */
/* eslint-disable import/extensions */
import React from "react";
import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
import LandingPage from "./pages/LandingPage/index";
import Header from "./components/Header/index";
import Footer from "./components/Footer/index";
import "bootstrap/dist/css/bootstrap.min.css";
import "./App.css";

function App() {
  return (
    <Router>
      <MainContent />
    </Router>
  );
}

function MainContent() {
  return (
    <div
      className="app-wrapper d-flex flex-column min-vh-100"
      style={{ backgroundColor: "#212529" }}
    >
      {/* Header Comp */}
      <Header />

      {/* Main Content */}
      <div className="container-bkp flex-fill">
        <Routes>
          <Route path="/" element={<LandingPage />} />
        </Routes>
      </div>

      {/* Footer Comp */}
      <Footer />
    </div>
  );
}

export default App;
