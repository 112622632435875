import React from "react";
import { Container } from "react-bootstrap";

function Footer() {
  return (
    <footer className="page-footer mt-auto py-3">
      <Container className="text-center" style={{ color: "white" }}>
        &copy; 2024 HeyNeighbors. All Rights Reserved.
      </Container>
    </footer>
  );
}

export default Footer;
